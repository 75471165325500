import { SplitText } from "@cyriacbr/react-split-text";
import * as React from "react";
import Helmet from "react-helmet";
import eventBus from "../components/utils/eventBus";
import gsap from "gsap";

import SliderSection from "../components/qui/sliderSection";

/** Import Image */
import testingImg from "../images/testing-image.jpg";

import headerImgDesktop from "../images/src/pages/menuiserie/desktop/menuiserie_1_desktop.jpg";
import headerImgDesktopR from "../images/src/pages/menuiserie/desktop/menuiserie_1_desktop@2x.jpg";

import firstImgDesktop from "../images/src/pages/menuiserie/desktop/menuiserie_2_desktop.jpg";
import firstImgDesktopR from "../images/src/pages/menuiserie/desktop/menuiserie_2_desktop@2x.jpg";
import secondaryImgDesktop from "../images/src/pages/menuiserie/desktop/menuiserie_3_desktop.jpg";
import secondaryImgDesktopR from "../images/src/pages/menuiserie/desktop/menuiserie_3_desktop@2x.jpg";
import thirdImgDesktop from "../images/src/pages/menuiserie/desktop/menuiserie_4_desktop.jpg";
import thirdImgDesktopR from "../images/src/pages/menuiserie/desktop/menuiserie_4_desktop@2x.jpg";

import headerImgMobile from "../images/src/pages/menuiserie/mobile/menuiserie_1_mobile.jpg";
import headerImgMobileR from "../images/src/pages/menuiserie/mobile/menuiserie_1_mobile@2x.jpg";

import firstImgMobile from "../images/src/pages/menuiserie/mobile/menuiserie_2_mobile.jpg";
import firstImgMobileR from "../images/src/pages/menuiserie/mobile/menuiserie_2_mobile@2x.jpg";
import secondaryImgMobile from "../images/src/pages/menuiserie/mobile/menuiserie_3_mobile.jpg";
import secondaryImgMobileR from "../images/src/pages/menuiserie/mobile/menuiserie_3_mobile@2x.jpg";
import thirdImgMobile from "../images/src/pages/menuiserie/mobile/menuiserie_4_mobile.jpg";
import thirdImgMobileR from "../images/src/pages/menuiserie/mobile/menuiserie_4_mobile@2x.jpg";

/**Styles */
import "../styles/qui/qui.scss";
class Us extends React.Component {
  componentDidMount() {
    gsap.set("#quiHeader, #quiHeader .line", { opacity: 0 });
    gsap.set("#histoire", { opacity: 0 });

    eventBus.on("Finalised", () => {
      gsap.set("#quiHeader", { opacity: 1 });
      gsap.set("#histoire", { opacity: 1 });

      let tl = gsap.timeline();
      tl.fromTo(
        "#quiHeader .line",
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
      );
      tl.fromTo(
        "#histoire .header-image",
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0 }
      );

      let histoireContent = document.querySelector("#histoire_content");
      let femmesTitle = document.querySelector("#profiles .secondary-title");
      let traditionTitle = document.querySelector(
        "#tradition .secondary-title"
      );
      this.frameTextMotion(femmesTitle, this.triggerSecondaryTitle);
      this.frameTextMotion(traditionTitle, this.triggerSecondaryTitle);
      this.frameTextMotion(histoireContent, this.triggerFrame);
    });
    document.querySelector(".main").classList.add("qui");
  }

  componentWillUnmount() {
    document.querySelector(".main").classList.remove("qui");
  }

  frameTextMotion = (el, trigger) => {
    let target = el;
    let prevRatio = 0.0;
    let prevY = 0.0;

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > prevRatio) {
          // Enter by top
          if (entry.boundingClientRect.y > prevY) {
            trigger(el);
            observer.unobserve(target);
          }
        } else {
        }
        observer.unobserve(target);
      });
    };

    let observer = new IntersectionObserver(callback);
    observer.observe(target);
  };

  triggerFrame(el) {
    let title = el.querySelector("h2");
    let texts = el.querySelectorAll("p");

    let tl = gsap.timeline();
    tl.fromTo(title, { opacity: 0 }, { opacity: 1 });
    tl.fromTo(texts, { opacity: 0 }, { opacity: 1, stagger: 0.2 });
  }

  triggerSecondaryTitle(el) {
    let tl = gsap.timeline();
    tl.fromTo(
      el.querySelectorAll(".line"),
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>La menuiserie I Menuiserie Perret</title>
          <meta
            name="description"
            type="description"
            content="Crée en 1906 dans les pentes de la Croix-Rousse, notre menuiserie est fière de perpétuer la longue tradition des métiers du bois."
          />
        </Helmet>
        <section className="Header" id="quiHeader">
          <h1 className="secondary-title">
            <SplitText
              LineWrapper={({ lineIndex, children }) => (
                <span className={"line wrapper" + " line" + lineIndex}>
                  {children}
                </span>
              )}
            >
              Les techniques se transmettent de génération en génération. Il
              faut les enrichir et les perfectionner constamment.
            </SplitText>
          </h1>
        </section>
        <section className="histoire" id="histoire">
          <picture className="header-image">
            <source
              media="(min-width: 770px)"
              srcSet={headerImgDesktopR + " 2x, " + headerImgDesktop + " 1x"}
            />
            <img
              src={headerImgMobile}
              srcSet={headerImgMobileR + " 2x, " + headerImgMobile + " 1x"}
              alt="Image de test"
            ></img>
          </picture>
          <div className="content" id="histoire_content">
            <h2 className="tertiary-title">
              Une histoire qui dure depuis 3 générations
            </h2>
            <p>
              L'entreprise Perret a été créée en 1906 par Jacques Perret,
              grand-père du dirigeant actuel. Initialement localisée sur les
              pentes de la Croix Rousse, au cœur de Lyon, la menuiserie
              abandonne sa domiciliation historique en 1945 pour s'implanter
              dans le quartier Paul Bert-Villette où elle restera plus de 50
              ans.
            </p>
            <p>
              Les ateliers déménagent ensuite à Chassieu, dans le grand est
              lyonnais, afin de trouver l'espace nécessaire à son développement
              et ses ambitions. Aujourd'hui notre menuiserie maîtrise l'ensemble
              de la chaîne, du bois brut sélectionné avec rigueur à la pose, un
              atout de poids pour garantir à nos clients des ouvrages de
              menuiserie sur mesure de qualité.
            </p>
          </div>
        </section>
        <section className="profiles" id="profiles">
          <div className="left">
            <h2 className="secondary-title">
              <SplitText
                LineWrapper={({ lineIndex, children }) => (
                  <span className={"line wrapper" + " line" + lineIndex}>
                    {children}
                  </span>
                )}
              >
                Des femmes et des hommes passionnés par le travail du bois{" "}
              </SplitText>
            </h2>
            <p>
              Fiers de perpétuer la longue tradition des métiers du bois, les
              membres de notre équipe sont des amoureux du détail. Ils sont
              attachés à une grande importance à la transmission des valeurs et
              de notre savoir-faire.{" "}
            </p>
          </div>
          <div className="right">
            <div className="parallax_elem" data-speed="-1"></div>
            <picture className="parallax_elem" data-speed="0">
              <source
                media="(min-width: 770px)"
                srcSet={firstImgDesktopR + " 2x, " + firstImgDesktop + " 1x"}
              />
              <img
                src={firstImgMobile}
                srcSet={firstImgMobileR + " 2x, " + firstImgMobile + " 1x"}
                alt="Image de test"
              ></img>
            </picture>
          </div>
        </section>
        <SliderSection></SliderSection>
        <section className="tradition" id="tradition">
          <div className="content">
            <picture className="content__splash parallax_elem" data-speed="0">
              <source
                media="(min-width: 770px)"
                srcSet={
                  secondaryImgDesktopR + " 2x, " + secondaryImgDesktop + " 1x"
                }
              />
              <img
                src={secondaryImgDesktop}
                srcSet={
                  secondaryImgDesktopR + " 2x, " + secondaryImgDesktop + " 1x"
                }
                alt="Image de test"
              ></img>
            </picture>
            <picture className="content__little parallax_elem" data-speed="-1">
              <source
                media="(min-width: 770px)"
                srcSet={thirdImgDesktopR + " 2x, " + thirdImgDesktop + " 1x"}
              />
              <img
                src={thirdImgMobile}
                srcSet={thirdImgMobileR + " 2x, " + thirdImgMobile + " 1x"}
                alt="Image de test"
              ></img>
            </picture>
            <div className="content__content">
              <h2 className="secondary-title">
                <SplitText
                  LineWrapper={({ lineIndex, children }) => (
                    <span className={"line wrapper" + " line" + lineIndex}>
                      {children}
                    </span>
                  )}
                >
                  Conjuguer tradition et modernité{" "}
                </SplitText>
              </h2>
              <p>
                Si depuis sa création, l'entreprise familiale accorde beaucoup
                d'importance à la transmission du savoir-faire, les trois
                générations de dirigeants ont su s'adapter à leur époque et aux
                innovations techniques pour maintenir un haut niveau de
                compétitivité. De la même façon, les menuisiers et ébénistes qui
                composent notre équipe se forment aux méthodes les plus modernes
                afin de faire évoluer leurs connaissances.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Us;
