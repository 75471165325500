import React from "react";
import gsap from "gsap";

import * as SectionStyle from "../../styles/qui/sliderSections.module.scss";
/** Import Image */
import testingImg from "../../images/testing-image.jpg";

import slideImgOneDesktop from "../../images/src/pages/menuiserie/desktop/slide_1_desktop.jpg";
import slideImgOneDesktopR from "../../images/src/pages/menuiserie/desktop/slide_1_desktop@2x.jpg";
import slideImgTwoDesktop from "../../images/src/pages/menuiserie/desktop/slide_2_desktop.jpg";
import slideImgTwoDesktopR from "../../images/src/pages/menuiserie/desktop/slide_2_desktop@2x.jpg";
import slideImgThreeDesktop from "../../images/src/pages/menuiserie/desktop/slide_3_desktop.jpg";
import slideImgThreeDesktopR from "../../images/src/pages/menuiserie/desktop/slide_3_desktop@2x.jpg";
import slideImgFourDesktop from "../../images/src/pages/menuiserie/desktop/slide_4_desktop.jpg";
import slideImgFourDesktopR from "../../images/src/pages/menuiserie/desktop/slide_4_desktop@2x.jpg";
import slideImgFiveDesktop from "../../images/src/pages/menuiserie/desktop/slide_5_desktop.jpg";
import slideImgFiveDesktopR from "../../images/src/pages/menuiserie/desktop/slide_5_desktop@2x.jpg";

import slideImgOneMobile from "../../images/src/pages/menuiserie/mobile/slide_1_mobile.jpg";
import slideImgOneMobileR from "../../images/src/pages/menuiserie/mobile/slide_1_mobile@2x.jpg";
import slideImgTwoMobile from "../../images/src/pages/menuiserie/mobile/slide_2_mobile.jpg";
import slideImgTwoMobileR from "../../images/src/pages/menuiserie/mobile/slide_2_mobile@2x.jpg";
import slideImgThreeMobile from "../../images/src/pages/menuiserie/mobile/slide_3_mobile.jpg";
import slideImgThreeMobileR from "../../images/src/pages/menuiserie/mobile/slide_3_mobile@2x.jpg";
import slideImgFourMobile from "../../images/src/pages/menuiserie/mobile/slide_4_mobile.jpg";
import slideImgFourMobileR from "../../images/src/pages/menuiserie/mobile/slide_4_mobile@2x.jpg";
import slideImgFiveMobile from "../../images/src/pages/menuiserie/mobile/slide_5_mobile.jpg";
import slideImgFiveMobileR from "../../images/src/pages/menuiserie/mobile/slide_5_mobile@2x.jpg";

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.pictures = [];
    this.texts = [" ", " ", " ", " ", " "];
    this.textNode = null;
  }

  componentDidMount() {
    this.pictures = document.querySelectorAll(`.${SectionStyle.right} picture`);
    this.textNode = document.querySelectorAll("p.text-node");
    this.index = this.pictures.length - 1;
    this.verify();
  }

  verify = () => {
    console.log(this.pictures);
    this.pictures.forEach((el) => (el.style.display = "none"));
    this.pictures[this.index].style.display = "block";
    this.textNode.forEach((el) => (el.innerHTML = this.texts[this.index]));
  };

  nextSlide = () => {
    gsap.fromTo(
      ".js_cover",
      { left: "200%" },
      {
        left: 0,
        onComplete: () => {
          if (this.index === this.pictures.length - 1) {
            this.index = 0;
            this.verify();
          } else {
            this.index = this.index + 1;
            this.verify();
          }
          gsap.fromTo(".js_cover", { left: 0 }, { left: "-200%" });
        },
      }
    );
  };

  precedentSlide = () => {
    gsap.fromTo(
      ".js_cover",
      { left: "-200%" },
      {
        left: 0,
        onComplete: () => {
          if (this.index === 0) {
            this.index = this.pictures.length - 1;
            this.verify();
          } else {
            this.index = this.index - 1;
            this.verify();
          }
          gsap.fromTo(".js_cover", { left: 0 }, { left: "200%" });
        },
      }
    );
  };

  render() {
    return (
      <section className={SectionStyle.section}>
        <div className={SectionStyle.left}>
          <div>
            <h2 className="tertiary-title">
              Deux ateliers pour répondre à tous vos besoins
            </h2>
            <p>
              Notre atelier de Chassieux est spécialisé dans la menuiserie
              intérieure et les réalisations d'agencement et notre atelier situé
              à Saint Laurent d'Agny est en charge de la menuiserie extérieure.
            </p>
            <p>
              En fonction de son utilisation, le bois n'a pas besoin des mêmes
              propriétés, le travail est différent et c'est dans cette logique
              que nous avons dissocié la production dans deux ateliers
              distincts.{" "}
            </p>
          </div>
          <div className="navigation">
            <button onClick={this.precedentSlide} aria-label="Precedent-Image">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="40" fill="#23201D" />
                <path
                  d="M21.4777 26L23 24.4725L18.0553 19.5L23 14.5275L21.4777 13L15 19.5L21.4777 26Z"
                  fill="#F3EBE2"
                />
              </svg>
            </button>
            <button onClick={this.nextSlide} aria-label="Image Suivante">
              <svg
                width="75"
                height="75"
                viewBox="0 0 75 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="75" height="75" fill="#23201D" />
                <path
                  d="M33.4737 27L31 29.4675L39.0351 37.5L31 45.5325L33.4737 48L44 37.5L33.4737 27Z"
                  fill="#F3EBE2"
                />
              </svg>
            </button>

            <p className="text-node">Atelier numero un</p>
          </div>
        </div>
        <div className={SectionStyle.right}>
          <div className={SectionStyle.navigation}>
            <button onClick={this.precedentSlide} aria-label="Image Précédente">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="29" height="29" fill="#F3EBE2" />
                <path
                  d="M16.8583 20L18 18.825L14.2915 15L18 11.175L16.8583 10L12 15L16.8583 20Z"
                  fill="#23201D"
                />
              </svg>
            </button>
            <button onClick={this.nextSlide} aria-label="Image Suivante">
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" fill="#F3EBE2" />
                <path
                  d="M21.7126 17L20 18.7625L25.5628 24.5L20 30.2375L21.7126 32L29 24.5L21.7126 17Z"
                  fill="#23201D"
                />
              </svg>
            </button>
            <p className="text-node">Atelier numero un</p>
          </div>
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={
                slideImgOneDesktopR + " 2x, " + slideImgOneDesktop + " 1x"
              }
            />
            <img src={slideImgOneMobile} srcSet={ slideImgOneMobileR + " 2x, " + slideImgOneMobile + " 1x"} alt="Image de test"></img>
          </picture>
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={
                slideImgTwoDesktopR + " 2x, " + slideImgTwoDesktop + " 1x"
              }
            />
            <img src={slideImgTwoMobile} srcSet={ slideImgTwoMobileR + " 2x, " + slideImgTwoMobile + " 1x" } alt="Image de test"></img>
          </picture>
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={
                slideImgThreeDesktopR + " 2x, " + slideImgThreeDesktop + " 1x"
              }
            />
            <img src={slideImgThreeMobile} srcSet={ slideImgThreeMobileR + " 2x, " + slideImgThreeMobile + " 1x" } alt="Image de test"></img>
          </picture>
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={
                slideImgFourDesktopR + " 2x, " + slideImgFourDesktop + " 1x"
              }
            />
            <img src={slideImgFourMobile} srcSet={ slideImgFourMobileR + " 2x, " + slideImgFourMobile + " 1x" } alt="Image de test"></img>
          </picture>
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={
                slideImgFiveDesktopR + " 2x, " + slideImgFiveDesktop + " 1x"
              }
            />
            <img src={slideImgFiveMobile} srcSet={ slideImgFiveMobileR + " 2x, " + slideImgFiveMobile + " 1x" } alt="Image de test"></img>
          </picture>
          <div className={SectionStyle.cover + " js_cover"}></div>
        </div>
      </section>
    );
  }
}

export default Slider;
